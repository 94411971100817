//require('./bootstrap');
//require('bootstrap/dist/js/bootstrap.bundle.min');

window.$ = window.jQuery = require("jquery/dist/jquery.slim.min");
window.fancybox = require("@fancyapps/fancybox/dist/jquery.fancybox.min");

/*window.Vue = require('vue').default;
import VueTheMask from 'vue-the-mask'*/
//import '@fortawesome/fontawesome-free/js/all.js';

/*Vue.component('site-catalog', require('./components/SiteCatalog.vue').default);
Vue.use(VueTheMask);

if (document.getElementById('app') !== null) {
    const app = new Vue({
        el: '#app'
    });
}*/

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function openPopup(id) {
    $.fancybox.open([
        {
            touch: false,
            src: '#' + id,
            titleShow: false,
            autoScale: true,
            openEffect: 'fade',
        }
    ]);
    return false;
}

if (typeof sendForm !== "undefined")
    if (sendForm === true)
        openPopup('success_popup');

let formList = document.getElementsByTagName('form');
let dopInfo = document.getElementById('dop_info-tpl');
let geoInput = document.createElement('input');

geoInput.name = 'geo';
geoInput.type = 'hidden';

/*let promise = new Promise((resolve, reject) => {
    if (getCookie('geo_param').length === 0) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", "/information", true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let json = JSON.parse(xhr.responseText);
                setCookie('geo_param', JSON.stringify(json));
                geoInput.value = JSON.stringify(json);
                resolve(geoInput);
            }
        };
        xhr.send();
    } else {
        geoInput.value = getCookie('geo_param');
        resolve(geoInput);
    }
});*/
for (let i = 0; i < formList.length; i++) {
    formList[i].appendChild(dopInfo.cloneNode(true));
}


/*promise.then(
    result =>  {
        for (let i = 0; i < formList.length; i++) {
            /!*    formList[i].addEventListener('submit', function (e) {
                   let button =  formList[i].querySelector('button[type="submit"]');
                    button.setAttribute('disabled','disabled');
                    button.disabled = true;
                });*!/
            formList[i].appendChild(result.cloneNode(true));
        }
    }
)*/

document.querySelector('body').addEventListener('click', function (e) {
    if (e.target.classList.contains('open_order_tech'))
        openPopup('popup-tech-form');
})


$(document).ready(function () {

    $("input[name='phone']")
        .on('input', function (e) {
            $(this).val($(this).val().replace(/[^0-9+()-]/g, ''));
        }).attr('maxlength', 18);

    $('body').on('submit', 'form', function () {
        let form = $(this);
        form.find('button[type="submit"]')
            //.attr('disabled','disabled')
            .html('Отправка...')
            .prop('disabled', true)
            .css('opacity', '0.7')
    });

    $('a[href*="#"]:not([href="#"]):not(.fancybox)').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                document.getElementById(this.hash.slice(1)).scrollIntoView({behavior: 'smooth', block: 'start'})
                return false;
            }
        }
    });

    $('body').on('change', '[name="discount"]', function () {
        let input = $(this);
        $('.discount-block .form-check').removeClass('checked');
        input.parent('.form-check').addClass('checked');
        let discount = input.data('discount');
        $('#discount_form').find('button[type="submit"]').html('Получить в подарок ' + discount);
    }).on('click', '.discount-block .form-check', function () {
        $(this).find('input').prop('checked', true).trigger('change');
    }).on('click', '[data-openPopup]', function (e) {
        e.preventDefault();
        openPopup($(this).attr('data-openPopup'))
        return false;
    });


})
$(document).mouseleave(function () {
    if (getCookie('exitPopup').length === 0) {
        $.fancybox.open([
            {
                touch: false,
                src: '#exit-popup-form',
                titleShow: false,
                autoScale: true,
                openEffect: 'fade',
                afterShow: function (instance, slide) {
                    setCookie('exitPopup', '1')
                }
                //modal: true
            }
        ]);
    }
});

